import KexFacet from 'Models/Search/KexFacet.interface';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'Theme/stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useFilterData } from 'context/filter.context';
import Fieldset from 'DesignSystem/Fieldset/Fieldset';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import { Fragment, useEffect, useState } from 'react';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import AccordionItem from 'DesignComponents/Molecules/Accordion/AccordionItem';
import { mapSearchTranslations } from 'Shared/Common/mapTranslation';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';

import { useTranslations } from '../../../../context/init-data.context';

export type SearchFilterFacetsPropTypes = {
  facets: KexFacet[];
  modalMode?: boolean;
};

function SearchFilterFacets({
  facets,
  modalMode = false,
}: SearchFilterFacetsPropTypes) {
  const {
    searchLabels: { filters: filtersText },
  } = useTranslations();
  const [filterData, dispatchFilterData] = useFilterData();
  const { searchLabels } = useTranslations();
  const isMobile = useMedia(mediaQueryTypes.bpMax375);

  const [currectClickedCheckbox, setCurrectClickedCheckbox] = useState<{
    group: string;
    value: string;
  }>();
  const [localFilterData, setLocalFilterData] = useState<
    Map<string, Set<string>>
  >(new Map());
  const [facetState, setFacetState] = useState<KexFacet[] | undefined>();
  const [disableAllCheckbox, setDisableAllCheckbox] = useState<boolean>();

  useEffect(() => {
    if (facets) setFacetState(facets);

    // when facets data, set all checkboxes available
    setDisableAllCheckbox(false);

    // Update facetState data that is responsible for facet checkbox representation
    setFacetState(facets);

    // update localFilterData with updated state from filterData.multiSelectFilters
    if (filterData.multiSelectFilters) {
      setLocalFilterData(filterData.multiSelectFilters);
    }
  }, [filterData]);

  const facetCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newGroupName = event.target.name;
    let newValue = event.target.value;

    setCurrectClickedCheckbox({ group: newGroupName, value: newValue });

    if (newValue.includes(' ')) {
      newValue = newValue.replace(/ /g, '+');
    }

    // Check if filterData.multiSelectFilters has size
    if (localFilterData.size > 0) {
      // Check if selected values groupName exist filterData.multiSelectFilters
      if (localFilterData.has(newGroupName)) {
        // Values groupName exist
        const valueToChange = localFilterData.get(newGroupName);

        if (valueToChange) {
          const valueToChangeArray = Array.from(valueToChange);

          if (valueToChangeArray.includes(newValue)) {
            // Delete if value exist in group values when clicked
            valueToChange.delete(newValue);
            if (valueToChange.size > 0) {
              // Update Flag/State with correct groupname and its values after delete above
              localFilterData.set(newGroupName, valueToChange);
            } else {
              // If no values after delete remove whole Map (groupName and it values)
              localFilterData.delete(newGroupName);
            }
          } else {
            // Add newValue to values in groupName when clicked
            localFilterData.set(newGroupName, valueToChange.add(newValue));
          }
        }
      } else {
        // groupName does not exist in Flag/State. Add GroupName and value
        const newGroupFilterValue = new Set<string>();
        localFilterData.set(newGroupName, newGroupFilterValue.add(newValue));
      }
    } else {
      // Init. Flag/State is empty, add first item (groupName and value)
      const setValues = new Set<string>();
      localFilterData.set(newGroupName, setValues.add(newValue));
    }

    // Disable all checkbox (enable in useEffect on top where data is update) and
    // make a BE request (also update filterProviderState inside filterAction function)
    setDisableAllCheckbox(true);

    dispatchFilterData({
      type: 'setMultiSelectFilters',
      selectFilters: localFilterData,
    });
  };

  const checkSelected = (value: string, facetGroup: string) => {
    let selectedResult = false;

    if (value.includes(' ')) {
      value = value.replace(/ /g, '+');
    }
    if (localFilterData.has(facetGroup)) {
      // group name exist on the checkbox being supervised
      const valueToCheck = localFilterData.get(facetGroup);
      if (valueToCheck) {
        selectedResult = valueToCheck.has(value);
      }
    }
    return !!selectedResult;
  };

  const countSelectedInGroup = (facet: KexFacet) => {
    let count = 0;
    for (const term of facet.terms) {
      if (checkSelected(term.term, facet.name)) {
        count++;
      }
    }
    return count;
  };

  return (
    <Wrapper>
      {!modalMode && (
        <Heading tag="h3" weights="bold" size={'m'} css={{ mb: '$s100' }}>
          {filtersText}
        </Heading>
      )}
      {facetState &&
        !isMobile &&
        facetState.map((item: KexFacet, index) =>
          modalMode ? (
            <Fragment key={`facet-${index}`}>
              <AccordionItem
                header={mapSearchTranslations(
                  searchLabels,
                  item.userFriendlyName
                )}
                css={{ borderBottom: '1px solid $staticBorderDefault' }}
                noOfItems={countSelectedInGroup(item)}
              >
                <FacetGroup modalMode>
                  <Fieldset>
                    {item.terms.map((checkboxItem, index) => (
                      <CheckboxWrapper key={index}>
                        <Checkbox
                          key={index}
                          groupName={item.name}
                          text={checkboxItem.userFriendlyName}
                          value={checkboxItem.term}
                          isDisabled={disableAllCheckbox}
                          isChecked={checkSelected(
                            checkboxItem.term,
                            item.name
                          )}
                          onCheck={(e) => facetCheckboxOnChange(e)}
                        />
                        {disableAllCheckbox &&
                          currectClickedCheckbox?.group === item.name &&
                          currectClickedCheckbox.value ===
                            checkboxItem.term && (
                            <StyledLoadingCircle>
                              <AnimatedIcon animation={'spinAnimation'}>
                                <LoadingCircle />
                              </AnimatedIcon>
                            </StyledLoadingCircle>
                          )}
                      </CheckboxWrapper>
                    ))}
                  </Fieldset>
                </FacetGroup>
              </AccordionItem>
            </Fragment>
          ) : (
            <Fragment key={`facet-${index}`}>
              <FacetGroup>
                <Fieldset legend={item.userFriendlyName}>
                  {item.terms.map((checkboxItem, index) => (
                    <CheckboxWrapper key={index}>
                      <Checkbox
                        key={index}
                        groupName={item.name}
                        text={checkboxItem.userFriendlyName}
                        value={checkboxItem.term}
                        isDisabled={disableAllCheckbox}
                        isChecked={checkSelected(checkboxItem.term, item.name)}
                        onCheck={(e) => facetCheckboxOnChange(e)}
                      />
                      {disableAllCheckbox &&
                        currectClickedCheckbox?.group === item.name &&
                        currectClickedCheckbox.value === checkboxItem.term && (
                          <StyledLoadingCircle>
                            <AnimatedIcon animation={'spinAnimation'}>
                              <LoadingCircle />
                            </AnimatedIcon>
                          </StyledLoadingCircle>
                        )}
                    </CheckboxWrapper>
                  ))}
                </Fieldset>
              </FacetGroup>
            </Fragment>
          )
        )}
    </Wrapper>
  );
}

export default SearchFilterFacets;

const Wrapper = styled('div', {
  w: '100%',
});

const FacetGroup = styled('div', {
  pb: 2,
  pt: 2,
  borderBottom: '1px solid $staticBorderDefault',
  '& fieldset': {
    w: '100%',
  },
  variants: {
    modalMode: {
      true: {
        pb: 1,
        pt: 0,
        '& fieldset': {
          mt: 0,
        },
      },
    },
  },
});

const CheckboxWrapper = styled('div', {
  position: 'relative',
});

const StyledLoadingCircle = styled('div', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  justifyContent: 'end',
});
