import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Fetcher from 'Shared/Common/Fetcher';
import { FILTER_URL_CONSTANTS } from 'Shared/Common/Helpers';
import FullSearchResult from 'Models/Search/FullSearchResultModel.interface';
import KexFacet from 'Models/Search/KexFacet.interface';
import { useFilterData } from 'context/filter.context';
import {
  convertStringToMap, createSorterModelFromQueryString, toQueryParams
} from 'Commerce/Organisms/FilterComponent/FilterReducer/filterReducerHelperFunctions';


import { useTranslations } from '../../../context/init-data.context';

let abortController: AbortController = new AbortController();

export enum SearchTypes {
  Categories = 'getCategory',
  Content = 'getContent',
  Empty = '',
  Products = 'getProduct',
}

const toParamString = (params: [string, string][]) =>
  params.map(([key, value]) => `${key}=${value}`).join('&');

const noResult = (result: FullSearchResult | undefined) => {
  if (result === undefined) {
    return false;
  }

  if (result.articleProducts?.items?.length) {
    return false;
  }

  if (result.magazines?.items?.length) {
    return false;
  }

  if (result.pages?.items?.length) {
    return false;
  }

  if (result.subscriptionOffers?.items?.length) {
    return false;
  }

  return true;
};

const merge = (
  fullFacets?: KexFacet[],
  filteredFacets?: KexFacet[]
): typeof fullFacets => {
  if (!fullFacets) return undefined;

  const mp = new Map(filteredFacets?.map((k) => [k.name, k] as const));

  return fullFacets
    .map((facet) => {
      const facetFilter = mp.get(facet.name);

      const filtered = new Map(
        facetFilter?.terms?.map((term) => [term.term, term.count])
      );

      const terms = facet.terms?.map((term) => {
        const count = filtered.get(term.term) || 0;
        return { ...term, count };
      });

      if (!terms?.length && facet.terms?.length) {
        return undefined;
      }

      return { ...facet, terms };
    })
    .filter((f): f is KexFacet => !!f);
};

export async function QuickSearch(
  searchPage: string,
  query: string,
  languageRoute: string,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  const url = `${searchPage}QuickSearch?query=${query}&language=${languageRoute}`;
  try {
    const res = await fetch(url);

    setIsLoading(false);
    if (res.ok) {
      const data = await res.json();

      return data; // as SearchPhraseResult;
    }
  } catch {
    return undefined;
  }
}

const getOnlyProducts: [string, string][] = [
  ['getProducts', 'true'],
  ['getMagazines', 'false'],
  ['getPages', 'false'],
];

export function useSearch(apiUrl: string, take: number, categoryCode?: string) {
  const [filterDataState, dispatchFilterData] = useFilterData();
  const [fullResult, setFullResult] = useState<FullSearchResult>();

  const [loadingMore, setLoadingMore] = useState(false);

  const takeParameter: [string, string][] = [['take', take.toString()]];

  const {
    searchLabels: { sortOrderLatest, sortOrderPriceAsc, sortOrderNameAsc },
  } = useTranslations();

  const orderTranslations = {
    '0': sortOrderLatest,
    '1': sortOrderPriceAsc,
    '2': sortOrderNameAsc,
  };

  const url = `${apiUrl}Search?`;

  useEffect(() => {
    const filters = convertStringToMap(window.location.search);

    dispatchFilterData({
      type: 'setMultiSelectFilters',
      selectFilters: filters,
    });

    let sortOrder = createSorterModelFromQueryString(
      window.location.search,
      orderTranslations
    );

    if (!sortOrder) {
      sortOrder = {
        text: sortOrderLatest,
        value: 0,
        selected: true,
      };
    }
    dispatchFilterData({
      type: 'setSorterFilter',
      value: sortOrder,
    });
  }, [window.location.search]);

  const filterParams = toQueryParams(window.location.search);

  const [, query] = filterParams.find(
    ([key]) => key === FILTER_URL_CONSTANTS.SEARCH_QUERY
  ) || [FILTER_URL_CONSTANTS.SEARCH_QUERY, ''];

  const requestURL = categoryCode
    ? url +
      toParamString([
        ...filterParams.filter((subArray) => !subArray.includes('query')),
        ...takeParameter,
        ...getOnlyProducts,
        [FILTER_URL_CONSTANTS.PARENT, categoryCode],
      ])
    : url + toParamString([...filterParams, ...takeParameter]);

  const result = useSWR(
    requestURL,

    async (url) => {
      if (!query && !categoryCode) {
        return undefined;
      }
      try {
        return await FetchSearch(url);
      } catch {
        return undefined;
      }
    },
    {
      fallbackData: fullResult,
      revalidateOnFocus: false,
    }
  );

  const getFacetQueryParam = (
    multiSelectFilters: Map<string, Set<string>>
  ): string => {
    const groupNameAndValues: [string, string][] = [];
    Array.from(multiSelectFilters)
      .filter(([, values]) => values.size > 0)
      .forEach(([key, values]) => {
        return groupNameAndValues.push([
          FILTER_URL_CONSTANTS.FILTER + key,
          Array.from(values).join(','),
        ]);
      });

    const facetParamQuery = groupNameAndValues
      .map((x, index) => {
        const ampersand = '&';
        let result = '';
        if (index + 1 === groupNameAndValues.length) {
          // last one
          result = `${x[0]}=${x[1]}`;
        } else {
          result = `${x[0]}=${x[1]}${ampersand}`;
        }
        return result;
      })
      .join('');

    return facetParamQuery ? '&' + facetParamQuery : '';
  };

  const paginate = useCallback(async () => {
    setLoadingMore(true);

    const skip = fullResult ? fullResult.articleProducts.items.length : 0;

    let moreUrl =
      url +
      toParamString([
        ...filterParams,
        ['skip', skip.toString()],
        ...takeParameter,
        [
          FILTER_URL_CONSTANTS.ORDER,
          filterDataState.sorterFilter.value.toString(),
        ],
        ...getOnlyProducts,
      ]) +
      getFacetQueryParam(filterDataState.multiSelectFilters);

    if (categoryCode) {
      moreUrl =
        moreUrl +
        '&' +
        toParamString([[FILTER_URL_CONSTANTS.PARENT, categoryCode]]);
    }

    try {
      const res = await FetchSearch(moreUrl);
      if (res) {
        setFullResult((r) => {
          return !r
            ? res
            : {
                ...r,
                articleProducts: {
                  ...res.articleProducts,
                  items: [
                    ...r.articleProducts.items,
                    ...res.articleProducts.items,
                  ],
                },
              };
        });
      }
    } finally {
      setLoadingMore(false);
    }
  }, [fullResult, url, filterParams]);

  const currentFacets = useMemo(
    () => merge(fullResult?.facets, result.data?.facets),
    [result.data?.facets, fullResult?.facets]
  );

  useEffect(() => {
    if (result.data) setFullResult(result.data);
  }, [result.data]);

  return {
    paginate,
    isLoading: result.isValidating,
    loadingMore,
    facets: currentFacets,
    sorters: result.data?.sorters,
    noResult: noResult(result.data),
    result: fullResult,
    error: result.error,
  };
}

// TODO: Replace with HTTP Client and Snackbar
function FetchSearch(url: string) {
  abortController = new AbortController();
  const { signal } = abortController;

  return Fetcher<FullSearchResult, FullSearchResult>(
    url,
    signal,
    (data, resolve) => {
      if (data?.facets) {
        data.facets;
      }
      resolve(data);
    },
    false
  );
}
