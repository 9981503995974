import { styled } from 'Theme/stitches.config';

const StyledLabel = {
  display: 'flex',
  alignItems: 'center',
  color: '$onSurface',
  fontWeight: '$fontWeightRegular',
  fontSize: '$fontSizes$fontSize50',
  lineHeight: '$lh20',
  textUnderlineOffset: '2px',

  variants: {
    disabled: {
      true: {
        color: '$interactiveDisabled_2',
      },
    },
    error: {
      true: {
        color: '$semanticError',
      },
    },
    interactive: {
      true: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    bold: {
      true: {
        fontFamily: 'fontBold',
      },
    },
    focusWithin: {
      true: {
        '&:focus-within': {
          outlineOffset: '2px',
          outline: 'solid 2px $focusBorder',
        },
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      interactive: true,
      css: {
        '&:hover': {
          textDecoration: 'none',
          cursor: 'not-allowed',
        },
      },
    },
    {
      disabled: true,
      error: true,
      css: {
        color: '$interactiveDisabled_2',
      },
    },
  ],
};

export const RegularLabel = styled('label', { ...StyledLabel });

export const ErrorLabel = styled('span', { ...StyledLabel });
